














































































































































import router from "@/router";
import Vue from "vue";
export default Vue.extend({
  name: "Navigation",
  data() {
    return {
      drawer: false,
      userName: "",
      userImgurl: "",
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      router.push({ path: "/login" });
    },
  },
  computed: {
    isSm() {
      const { sm, xs } = this.$vuetify.breakpoint;
      var isSM = sm || xs;
      return isSM;
    },
    user() {
      return this.$store.state.user;
    },
    items() {
      const items = [
        {
          title: "Home",
          route: "/",
          icon: "mdi-home",
        },
        {
          title: "Dashboard",
          route: "/dashboard",
          icon: "mdi-monitor-dashboard",
          isNeedAuthorized: true,
          format:1 //notification
        },
        {
          title: "Leagues",
          route: "/leagues",
          icon: "mdi-account-group",
        },
        {
          title: "Information",
          route: "/information",
          icon: "mdi-clipboard-text-outline",
        },
        {
          title: "Get Started",
          route: "/get-started",
          icon: "mdi-tournament",
        },
        {
          title: "Prizes",
          route: "/prizes",
          icon: "mdi-seal",
        },
        {
          title: "Shop",
          route: "https://badmintonconnectshop.square.site/",
          icon: "mdi-cart",
          format:2 //link
        },
        {
          title: "Contact US",
          route: "/contact",
          icon: "mdi-card-account-phone",
        },
      ];
      return items.filter(
        (a) => !a.isNeedAuthorized || !this.$store.state.unauthorized
      );
    },
  },
});
