
















































































import badmintonApi from "@/plugins/Api";
import { MessageUIModel } from "../models/index";
import {
  AcceptTeamRequestCommand,
  MessageModel,
  RejectTeamRequestCommand,
  SetMessageToReadCommand,
} from "@/api_client/models";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      messages: [] as MessageUIModel[],
    };
  },
  methods: {
    accept(message: any) {
      var command = {
        messageId: message.id,
      } as AcceptTeamRequestCommand;
      message.isAccepting = true;
      badmintonApi.MessagesApi?.apiMessagesAcceptPut(command).then(
        (response) => {
          message.isAccepting = false;
          this.loadMessage();
        },
        (error) => {
          message.isAccepting = false;
        }
      );
    },
    reject(message: any) {
      var command = {
        messageId: message.id,
      } as RejectTeamRequestCommand;
      message.isRejecting = true;
      badmintonApi.MessagesApi?.apiMessagesRejectPut(command).then(
        (response) => {
          message.isRejecting = false;
          this.loadMessage();
        },
        (error) => {
          message.isRejecting = false;
        }
      );
    },
    MardAsRead(message: any) {
      var command = {
        messageId: message.id,
      } as SetMessageToReadCommand;
      message.isMarkingAsRead = true;
      badmintonApi.MessagesApi?.apiMessagesReadPut(command).then(
        (response) => {
          message.isMarkingAsRead = false;
          this.loadMessage();
        },
        (error) => {
          message.isMarkingAsRead = false;
        }
      );
    },
    loadMessage() {
      badmintonApi.MessagesApi?.apiMessagesGet().then(
        (response) => {
          response.data.value.map((a: MessageUIModel) => {
            a.isRejecting = false;
            a.isAccepting = false;
            a.isMarkingAsRead = false;
          });

          this.messages = response.data.value as MessageUIModel[];

          this.$store.dispatch(
            "UpdateUnreadMessageNumber",
            this.messages.filter((a) => !a.isRead).length
          );
        }
      );
    },
  },
  mounted() {
    this.loadMessage();
  },
});
