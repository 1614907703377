


















































































































import Modal from "../components/Modal.vue";
import JoinLeague from "../components/JoinLeague.vue";
import LeagueCard from "../components/LeagueCard.vue";
import Vue from "vue";
import badmintonApi from "@/plugins/Api";
import {
  City,
  getEnumValues,
  LeagueType,
  Season,
  SkillLevel,
} from "@/models/enums";
export default Vue.extend({
  data() {
    return {
      leagues: [],
      LeagueTypes: ["All"].concat(getEnumValues(LeagueType)),
      SkillLevels: ["All"].concat(getEnumValues(SkillLevel)),
      Seasons: ["All"].concat(getEnumValues(Season)),
      leagueType: "All",
      skillLevel: "All",
      city: "All",
      season: "All",
      cities: ["All"].concat(getEnumValues(City)),
    };
  },
  methods: {   
    loadLeagues() {
      badmintonApi.LeaguesApi?.apiLeaguesActiveGet(
        undefined,
        this.city,
        this.season,
        this.leagueType,
        this.skillLevel
      ).then((response) => {
        this.leagues = response.data.value;
      });
    },
  },

  mounted() {
    var user = this.$store.state.user;
    if (user.city) {
      this.city = user.city;
      this.skillLevel = user.skillLevel;
    }
    this.loadLeagues();
  },
  components: {
    Modal,
    JoinLeague,
    LeagueCard,
  },
});
