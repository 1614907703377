































































import badmintonApi from "@/plugins/Api";
import router from "@/router";
import { ChangePasswordCommand } from "@/api_client/models";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isChangePasswordFormValid: false,
      isResettingPassword: false,
      showChangePasswordFormErrorMessage: false,
      changePasswordFormErrorMessage: "",
      password: "",
      passwordRules: [(v: any) => !!v || "Password is required"],
      showConfirmPassword: false,
      showOldPassword: false,
      showPassword: false,
      oldPassword: "",
      oldPasswordRules: [(v: any) => !!v || "Password is required"],
      confirmPassword: "",
      confirmPasswordRules: [(v: any) => !!v || "Confirm password is required"],
    };
  },

  methods: {
    checkConfirmPassword(confirmPassword: any) {
      return (
        confirmPassword == this.password ||
        "Confirm password must be the same as password."
      );
    },
    changePassword() {
      var form = this.$refs?.resetPasswordForm as any;
      if (form.validate()) {
        this.isResettingPassword = true;
        this.showChangePasswordFormErrorMessage = false;
        var changePasswordCommand = {
          oldPassword: this.oldPassword,
          password: this.password,
        } as ChangePasswordCommand;
        badmintonApi.UsersApi?.apiUsersChangePasswordPut(
            changePasswordCommand
          )
          .then(
            (response) => {
              this.isResettingPassword = false;
              router.push({ path: "/login" });
            },
            (error) => {
              this.isResettingPassword = false;
              this.showChangePasswordFormErrorMessage = true;
              this.changePasswordFormErrorMessage = error.response.data.Message;
            }
          );
      }
    },
  },
});
