import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import AdminHome from '../views/Admin/AdminHome.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Index from "../components/Index.vue"
import Information from "../views/Information.vue"
import GetStarted from "../views/GetStarted.vue"
import Leagues from "../views/Leagues.vue"
import UserManagement from "../views/Admin/UserManagement.vue"
import LeagueManagement from "../views/Admin/LeagueManagement.vue"
import Contact from "../views/Contact.vue"
import PaymentSuccess from "../views/PaymentSuccess.vue"
import PaymentCancel from "../views/PaymentCancel.vue"
import Dashboard from "../views/Dashboard.vue"
import UserDashboard from "../views/UserDashboard.vue"
import LeagueDetail from "../views/LeagueDetail.vue"
import Notifications from "../views/Notifications.vue"
import SubmitMatchResult from "../views/SubmitMatchResult.vue"
import MatchHistory from "../views/MatchHistory.vue"
import AdminNotifications from "../views/Admin/AdminNotifications.vue"
import Prizes from "../views/Prizes.vue"
import Shop from "../views/Shop.vue"

import TotalStats from "../views/TotalStats.vue"
Vue.use(VueRouter)
const routes: Array<RouteConfig> = [

  {
    path: '/',
    component: Home,
      children: 
      [
        {
          path: '',
          component: Index,
        }, {
          path: 'contact',
          component: Contact,
        },
        {
          path: 'leagues',
          component: Leagues,
        },
        {
          path: 'information',
          component: Information,
        },
        {
          path: 'dashboard',
          component: Dashboard,
        },
        {
          path: 'dashboard/:userId',
          component: UserDashboard,
        },
        {
          path: 'league-detail/:id',
          component: LeagueDetail,
        },
        {
          path: 'notifications',
          component: Notifications,
        },
        {
          path: 'submit-match-result',
          component: SubmitMatchResult,
        },
        {
          path: 'match-history',
          component: MatchHistory,
        },
        {
          path: 'total-stats',
          component: TotalStats,
        },
        {
          path: 'get-started',
          component: GetStarted,
        },
        {
          path: 'prizes',
          component: Prizes,
        },
        {
          path: 'Shop',
          component: Shop,
        }
      ]
  },

  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/forgot-password',
    component: ForgetPassword
  },
  {
    path: '/change-password',
    component: ChangePassword
  },
  {
    path: '/payment-success',
    component: PaymentSuccess
  },
  {
    path: '/payment-cancel',
    component: PaymentCancel
  },
  {
    path: '/admin',
    component: AdminHome,
    beforeEnter: (to, from, next) => {
      // if (store.state.user.isManager) {
      //   next();
      // } else {
      //   next("/");
      // }
      next();
    },
    children: [
      {
        path: '',
        redirect:"user-management"
      },
      {
        path: 'user-management',
        component: UserManagement,
      },
      {
        path: 'league-management',
        component: LeagueManagement,
      },
      {
        path: 'notifications',
        component: AdminNotifications,
      },
      {
        path: 'edit-match-result/:matchId/:messageId',
        component: SubmitMatchResult,
      },
      {
        path: 'edit-match-result/:matchId',
        component: SubmitMatchResult,
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
