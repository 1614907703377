



















































import Vue from "vue";

export default Vue.extend({
  props: {
    league: Object,
  },
  methods:{
     getLeagueTypeDisplayName(type: string) {
      var xx= this.$store.state.LeagueTypeMapping.find(
        (b: any) => b.LeagueType == type
      )?.DisplayValue;
      return xx;
    },
    getSkillLevelDisplayName(skillLevel: string) {
      return this.$store.state.SkillLevelMapping.find(
        (b: any) => b.SkillLevel == skillLevel
      )?.DisplayValue;
    },

  }
});
