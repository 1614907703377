















































import badmintonApi from "@/plugins/Api";
import router from "@/router";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      showPassword: false,
      isLogining: false,
      valid: false,
      showErrorMessage: false,
      errorMessage: "",
      email: "",
      emailRules: [
        (v: any) => !!v || "E-mail is required",
        (v: any) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v: any) => !!v || "Password is required"],
      password: "",
    };
  },

  methods: {
    login() {
      var form = this.$refs?.form as any;
      if (form.validate()) {
        var login = {
          email: this.email,
          password: this.password,
        };
        this.isLogining = true;
        badmintonApi.UsersApi?.apiLoginPost(login).then(
          (response: any) => {
            var token = response.data.value.token;
            badmintonApi.Axios.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            localStorage.setItem("token", token);
            if (response.data.value.isManager) {
              router.push({ path: "/admin" });
            } else {
              router.push({ path: "/" });
            }
            this.isLogining = false;
          },
          (error: any) => {
            this.showErrorMessage = true;
            this.errorMessage = error.response.data.Message;
            this.isLogining = false;
          }
        );
      }
    },
  },
});
