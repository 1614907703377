









































































































































































































































































































































































































































import badmintonApi from "@/plugins/Api";
import {
  BioInfo,
  LeagueModel,
  MailingAddress,
  MatchModel,
  UpdateProfileCommand,
  UserModel,
} from "@/api_client/models";
import Vue from "vue";
import TeamRequest from "../components/TeamRequest.vue";
import Modal from "../components/Modal.vue";
import LeagueCard from "../components/LeagueCard.vue";
import MatchResultChangeRequest from "../components/MatchResultChangeRequest.vue";
import { getEnumValues, SkillLevel } from "@/models/enums";
export default Vue.extend({
  data: () => ({
    isShowTeamRequestSuccessDialog: false,
    isShowChangeMatchResultDialog: false,
    isShowFindPartnerDialog: false,
    valid: false,
    isEditing: false,
    imgFile: null,
    email: "",
    referralCode: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    birthday: "",
    skillLevel: "",
    tShirtSize: "",
    gender: "",
    city: "",
    cities: ["Boston", "Chicago"],
    skilllevels: getEnumValues(SkillLevel),
    tShritSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    homeCourt: "",
    preferredTimeToPlay: "",
    bioInfo: {} as BioInfo,
    matches: [] as MatchModel[],
    mailingAddresses: [{}] as MailingAddress[],
    menu: false,
    isUpdating: false,
    inRegistrationLeagues: [] as LeagueModel,
    activeLeagues: [] as LeagueModel,
    selectedLeague: null as LeagueModel | null,
    selectedMatch: null as MatchModel | null,
    user: null as UserModel | null,
  }),
  methods: {
    fileChanged() {
      if (this.$store.state.user.isManager) {
        badmintonApi.UsersApi?.apiUsersUpdateProfileImgPut(
          this.imgFile as any,
          this.$route.params.userId
        ).then(async (response) => {
          if (this.user) {
            this.user.picture = response.data.value;
          }
        });
      }
    },
    async copy(text: string) {
      await navigator.clipboard.writeText(text);
      alert("Text copied to clipboard");
    },
    save() {
      var form = this.$refs.profileForm as any;
      if (form.validate()) {
        var command = {
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          birthday: this.birthday,
          skillLevel: this.skillLevel,
          tShirtSize: this.tShirtSize,
          gender: this.gender,
          mailingAddresses: this.mailingAddresses,
          city: this.city,
          homeCourt: this.homeCourt,
          preferredTimeToPlay: this.preferredTimeToPlay,
          bioInfo: this.bioInfo,
        } as UpdateProfileCommand;
        this.isUpdating = true;
        badmintonApi.UsersApi?.apiUsersUserIdUpdateProfilePut(
          this.$route.params.userId,
          command
        ).then(
          async (response) => {
            await this.$store.dispatch("loadUser");
            this.isEditing = false;
            this.isUpdating = false;
            this.loadUser();
          },
          (error) => {
            this.isUpdating = false;
          }
        );
      }
    },
    uploadImg() {
      if (this.$store.state.user.isManager) {
        var fileUpload = this.$refs.fileUpload as any;
        fileUpload.$el.firstElementChild.firstElementChild.firstElementChild.click();
      }
    },
    setUserInfo(user: any) {
      if (!user) return;
      this.isEditing = !user.firstName;
      this.email = user.email;
      this.referralCode = user.myReferralCode;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.phoneNumber = user.phoneNumber;
      this.birthday = user.dateOfBirth?.substr(0, 10);
      this.skillLevel = user.skillLevel;
      this.tShirtSize = user.tShirtSize;
      this.gender = user.gender;
      this.city = user.city;
      this.homeCourt = user.homeCourt;
      this.preferredTimeToPlay = user.preferredTimeToPlay;
      this.bioInfo = user.bioInfo ?? {};
      this.mailingAddresses = JSON.parse(
        JSON.stringify(user.mailingAddresses ?? [])
      );
      if (this.mailingAddresses.length == 0) {
        this.mailingAddresses.push({});
      }
    },

    loadUserLeagues() {
      badmintonApi.LeaguesApi?.apiLeaguesUserIdGet(
        this.$route.params.userId
      ).then(
        (response) => {
          this.activeLeagues = response.data.value.filter(
            (a: any) => a.nextTask == "None"
          );
          this.inRegistrationLeagues = response.data.value.filter(
            (a: any) => a.nextTask != "None"
          );
        },
        (error) => {}
      );
    },
    loadUserMatches() {
      badmintonApi.LeaguesApi?.apiLeaguesMatchesLatestPerLeagueUserIdGet(
        this.$route.params.userId
      ).then(
        (response) => {
          this.matches = response.data.value;
        },
        (error) => {}
      );
    },
    loadUser() {
      badmintonApi.UsersApi?.apiUsersIdBasicInfoGet(
        this.$route.params.userId
      ).then(
        (response) => {
          this.user = response.data.value;
          this.setUserInfo(response.data.value);
        },
        (error) => {}
      );
    },
  },

  mounted() {
    this.loadUser();
    this.loadUserLeagues();
    this.loadUserMatches();
  },
  components: {
    TeamRequest,
    Modal,
    MatchResultChangeRequest,
    LeagueCard,
  },
});
