


















































import router from "@/router";
import Vue from "vue";
import Index from "../components/Index.vue";
import Navigation from "../components/Navigation.vue";
export default Vue.extend({
  name: "Home",
  data() {
    return {
      img:
        router.currentRoute.path == "/"
          ? require("../assets/background.jpg")
          : "",
    };
  },
  computed: {
    user: function () {
      return this.$store.state.user;
    },
  },
  watch: {
    user(newUser) {
      if (!newUser.firstName) {
        router.push({ path: "/dashboard" });
      }
    },
    $route(to, from) {
      if (to.path == "/") {
        this.img = require("../assets/background.jpg");
      } else {
        this.img = "";
      }
    },
  },
  mounted() {
    this.$store.dispatch("UpdateErrorMessageDialog", {});
    this.$store.dispatch("loadUser");
  },
  components: {
    Index,
    Navigation,
  },
});
