




































































































































































































































































































































































































































































































































































































import badmintonApi from "@/plugins/Api";
import {
  BioInfo,
  LeagueModel,
  MailingAddress,
  MatchModel,
  UpdateProfileCommand,
} from "@/api_client/models";
import Vue from "vue";
import TeamRequest from "../components/TeamRequest.vue";
import Modal from "../components/Modal.vue";
import LeagueCard from "../components/LeagueCard.vue";
import MatchResultChangeRequest from "../components/MatchResultChangeRequest.vue";
import { getEnumValues, SkillLevel, City } from "@/models/enums";
import router from "@/router";
export default Vue.extend({
  data: () => ({
    isShowTeamRequestSuccessDialog: false,
    isShowChangeMatchResultDialog: false,
    isShowFindPartnerDialog: false,
    valid: false,
    isEditing: false,
    imgFile: null,
    email: "",
    referralCode: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    birthday: "",
    skillLevel: "",
    tShirtSize: "",
    gender: "",
    city: "",
    cities: getEnumValues(City),
    skilllevels: getEnumValues(SkillLevel),
    tShritSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    homeCourt: "",
    preferredTimeToPlay: "",
    bioInfo: {} as BioInfo,
    matches: [] as MatchModel[],
    mailingAddresses: [{}] as MailingAddress[],
    menu: false,
    isUpdating: false,
    inRegistrationLeagues: [] as LeagueModel,
    activeLeagues: [] as LeagueModel,
    selectedLeague: null as LeagueModel | null,
    selectedMatch: null as MatchModel | null,
    token: "" as string | null,
    baseURL: (process.env.VUE_APP_API_URL || "")
      .replace(new RegExp("[/]+$"), "")
      .concat("/"),
  }),
  methods: {
    showChangeMatchResultDialog(match: MatchModel) {
      this.selectedMatch = match;
      this.isShowChangeMatchResultDialog = true;
    },
    fileChanged() {
      badmintonApi.UsersApi?.apiUsersUpdateProfileImgPut(
        this.imgFile as any,
        this.$store.state.user.id
      ).then(
        async (response) => {
          await this.$store.dispatch(
            "UpdateUserProfileImg",
            response.data.value
          );
        },
        (error) => {}
      );
    },
    async copy(text: string) {
      await navigator.clipboard.writeText(text);
      alert("Text copied to clipboard");
    },
    save() {
      var form = this.$refs.profileForm as any;
      if (form.validate()) {
        var command = {
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          birthday: this.birthday,
          skillLevel: this.skillLevel,
          tShirtSize: this.tShirtSize,
          gender: this.gender,
          mailingAddresses: this.mailingAddresses,
          city: this.city,
          homeCourt: this.homeCourt,
          preferredTimeToPlay: this.preferredTimeToPlay,
          bioInfo: this.bioInfo,
        } as UpdateProfileCommand;
        this.isUpdating = true;
        badmintonApi.UsersApi?.apiUsersUpdateProfilePut(command).then(
          async (response) => {
            await this.$store.dispatch("loadUser");
            this.isEditing = false;
            this.isUpdating = false;
          },
          (error) => {
            this.isUpdating = false;
          }
        );
      }
    },
    uploadImg() {
      var fileUpload = this.$refs.fileUpload as any;
      fileUpload.$el.firstElementChild.firstElementChild.firstElementChild.click();
    },
    setUserInfo(user: any) {
      if (!this.$store.state.unauthorized) {
        this.isEditing = !user.firstName;
        this.email = user.email;
        this.referralCode = user.myReferralCode;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.phoneNumber = user.phoneNumber;
        this.birthday = user.dateOfBirth?.substr(0, 10);
        this.skillLevel = user.skillLevel;
        this.tShirtSize = user.tShirtSize;
        this.gender = user.gender;
        this.city = user.city;
        this.homeCourt = user.homeCourt;
        this.preferredTimeToPlay = user.preferredTimeToPlay;
        this.bioInfo = user.bioInfo ?? {};
        this.mailingAddresses = JSON.parse(
          JSON.stringify(user.mailingAddresses ?? [])
        );
        if (this.mailingAddresses.length == 0) {
          this.mailingAddresses.push({});
        }
      }
    },

    findPartner(league: any) {
      badmintonApi.LeaguesApi?.apiLeaguesIdGet(league.id).then((response) => {
        this.selectedLeague = response.data.value;
        this.isShowFindPartnerDialog = true;
      });
    },
    loadUserLeagues() {
      badmintonApi.LeaguesApi?.apiLeaguesUserGet().then(
        (response) => {
          this.activeLeagues = response.data.value.filter(
            (a: any) => a.nextTask == "None"
          );
          this.inRegistrationLeagues = response.data.value.filter(
            (a: any) => a.nextTask != "None"
          );
        },
        (error) => {}
      );
    },

    loadUserMatches() {
      badmintonApi.LeaguesApi?.apiLeaguesMatchesLatestPerLeagueGet().then(
        (response) => {
          this.matches = response.data.value;
        },
        (error) => {}
      );
    },
  },
  computed: {
    user() {
      return this.$store.state?.user;
    },
  },
  watch: {
    user(user) {
      this.setUserInfo(user);
    },
  },
  mounted() {
    this.token = localStorage.getItem("token");
    this.setUserInfo(this.user);
    this.loadUserLeagues();
    this.loadUserMatches();
  },
  components: {
    TeamRequest,
    Modal,
    MatchResultChangeRequest,
    LeagueCard,
  },
});
