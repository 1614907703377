import { FileUploadApi, LeaguesApi, MessagesApi, UsersApi } from "@/api_client";
import axios, { AxiosInstance } from "axios";
import store from "../store"
import router from "@/router";
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: (process.env.VUE_APP_API_URL || "").replace(new RegExp("[/]+$"), "").concat("/"),
  timeout: 30 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);
let token = localStorage.getItem('token');
if (token) {
  _axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
_axios.interceptors.request.use(
  function (config) {
    if(config.method=="get"){
      store.dispatch("UpdateLoadingStatus",true);
    }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    store.dispatch("UpdateLoadingStatus",false);
    // Do something with response data
    return response;
  },
  function (error) {
    store.dispatch("UpdateLoadingStatus",false);
    if (error.response.status == "401") {
      if (location.pathname.startsWith("/admin/")) {
        router.push({ path: "/login" });
      }
    }
    else if (error.response.status == "400" || error.response.status == "500") {
      store.dispatch('UpdateErrorMessageDialog',
        {
          isShowDialog: true,
          title: error.response.statusText,
          content: error.response.data.Message,
          errorType: error.response.status,
          errors: error.response.data.Errors
        })
    }
    else if (error.response.status == "403") {
      store.dispatch('UpdateErrorMessageDialog',
        {
          isShowDialog: true,
          title: error.response.statusText,
          content: "You don’t have permission to access!",
          errorType: error.response.status
        })
    }
    return Promise.reject(error);
  }
);
class BadmintonApi {
  Axios: AxiosInstance;
  MessagesApi: MessagesApi | undefined;
  LeaguesApi: LeaguesApi | undefined;
  FileUploadApi: FileUploadApi | undefined;
  UsersApi: UsersApi | undefined;
  constructor() {
    this.Axios = _axios;
    this.MessagesApi = new MessagesApi(undefined, "", _axios);
    this.LeaguesApi = new LeaguesApi(undefined, "", _axios);
    this.FileUploadApi = new FileUploadApi(undefined, "", _axios);
    this.UsersApi = new UsersApi(undefined, "", _axios);
  }
}
const badmintonApi = new BadmintonApi()
export default badmintonApi;
