/* tslint:disable */
/* eslint-disable */
/**
 * Badminton Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AddPlayersToPlayoffsGameCommand } from '../models';
import { AddTop8PlayersToPlayoffsGameCommand } from '../models';
import { BooleanResponseResult } from '../models';
import { ChangeMatchResultCommand } from '../models';
import { CreateLeagueCommand } from '../models';
import { GeneratePlayoffsGameCommand } from '../models';
import { JoinLeagueCommand } from '../models';
import { LeagueDetailModelListResponseResult } from '../models';
import { LeagueDetailModelResponseResult } from '../models';
import { LeagueModelListResponseResult } from '../models';
import { LeagueModelPaginationModelResponseResult } from '../models';
import { MatchModelListResponseResult } from '../models';
import { MatchModelPaginationModelResponseResult } from '../models';
import { MatchModelResponseResult } from '../models';
import { MovePlayerToLeagueCommand } from '../models';
import { RemovePlayersFromPlayoffsGameCommand } from '../models';
import { SetPlayoffsRoundPeriodCommand } from '../models';
import { SubmitMatchResultCommand } from '../models';
import { UpdateLeagueCommand } from '../models';
import { UpdateMatchResultCommand } from '../models';
import { UserLeagueModelListResponseResult } from '../models';
/**
 * LeaguesApi - axios parameter creator
 * @export
 */
export const LeaguesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [city] 
         * @param {string} [season] 
         * @param {string} [leagueType] 
         * @param {string} [skillLevel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesActiveGet: async (userId?: string, city?: string, season?: string, leagueType?: string, skillLevel?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (city !== undefined) {
                localVarQueryParameter['City'] = city;
            }

            if (season !== undefined) {
                localVarQueryParameter['Season'] = season;
            }

            if (leagueType !== undefined) {
                localVarQueryParameter['LeagueType'] = leagueType;
            }

            if (skillLevel !== undefined) {
                localVarQueryParameter['SkillLevel'] = skillLevel;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeMatchResultCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesAddMatchChangeRequestPut: async (body?: ChangeMatchResultCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/add-match-change-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddPlayersToPlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesAddPlayersToPlayoffsGamePut: async (body?: AddPlayersToPlayoffsGameCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/add-players-to-playoffs-game`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddTop8PlayersToPlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesAddTop8playersToPlayoffsGamePut: async (body?: AddTop8PlayersToPlayoffsGameCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/add-top-8players-to-playoffs-game`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesChangeLeagueStatusPut: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/change-league-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GeneratePlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesGeneratePlayoffsGamePut: async (body?: GeneratePlayoffsGameCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/generate-playoffs-game`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [countPerPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesGet: async (pageNumber?: number, countPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (countPerPage !== undefined) {
                localVarQueryParameter['countPerPage'] = countPerPage;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiLeaguesIdDelete.');
            }
            const localVarPath = `/api/Leagues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiLeaguesIdGet.');
            }
            const localVarPath = `/api/Leagues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JoinLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesJoinPut: async (body?: JoinLeagueCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [countPerPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesGet: async (pageNumber?: number, countPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (countPerPage !== undefined) {
                localVarQueryParameter['countPerPage'] = countPerPage;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesLatestPerLeagueGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/matches/latest-per-league`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesLatestPerLeagueUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling apiLeaguesMatchesLatestPerLeagueUserIdGet.');
            }
            const localVarPath = `/api/Leagues/matches/latest-per-league/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesMatchIdGet: async (matchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchId' is not null or undefined
            if (matchId === null || matchId === undefined) {
                throw new RequiredError('matchId','Required parameter matchId was null or undefined when calling apiLeaguesMatchesMatchIdGet.');
            }
            const localVarPath = `/api/Leagues/matches/{matchId}`
                .replace(`{${"matchId"}}`, encodeURIComponent(String(matchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} matchId 
         * @param {UpdateMatchResultCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesMatchIdPut: async (matchId: string, body?: UpdateMatchResultCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchId' is not null or undefined
            if (matchId === null || matchId === undefined) {
                throw new RequiredError('matchId','Required parameter matchId was null or undefined when calling apiLeaguesMatchesMatchIdPut.');
            }
            const localVarPath = `/api/Leagues/matches/{matchId}`
                .replace(`{${"matchId"}}`, encodeURIComponent(String(matchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MovePlayerToLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMovePlayerToLeaguePut: async (body?: MovePlayerToLeagueCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/move-player-to-league`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesOngoingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/ongoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesPost: async (body?: CreateLeagueCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesPut: async (body?: UpdateLeagueCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemovePlayersFromPlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesRemovePlayersFromPlayoffsGamePut: async (body?: RemovePlayersFromPlayoffsGameCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/remove-players-from-playoffs-game`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPlayoffsRoundPeriodCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesSetRoundPeriodToPlayoffsGamePut: async (body?: SetPlayoffsRoundPeriodCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/set-round-period-to-playoffs-game`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitMatchResultCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesSubmitMatchResultPut: async (body?: SubmitMatchResultCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/submit-match-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Leagues/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesUserIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiLeaguesUserIdGet.');
            }
            const localVarPath = `/api/Leagues/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeaguesApi - functional programming interface
 * @export
 */
export const LeaguesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [city] 
         * @param {string} [season] 
         * @param {string} [leagueType] 
         * @param {string} [skillLevel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesActiveGet(userId?: string, city?: string, season?: string, leagueType?: string, skillLevel?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueModelListResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesActiveGet(userId, city, season, leagueType, skillLevel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ChangeMatchResultCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesAddMatchChangeRequestPut(body?: ChangeMatchResultCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesAddMatchChangeRequestPut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AddPlayersToPlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesAddPlayersToPlayoffsGamePut(body?: AddPlayersToPlayoffsGameCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesAddPlayersToPlayoffsGamePut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AddTop8PlayersToPlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesAddTop8playersToPlayoffsGamePut(body?: AddTop8PlayersToPlayoffsGameCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesAddTop8playersToPlayoffsGamePut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesChangeLeagueStatusPut(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesChangeLeagueStatusPut(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GeneratePlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesGeneratePlayoffsGamePut(body?: GeneratePlayoffsGameCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesGeneratePlayoffsGamePut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [countPerPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesGet(pageNumber?: number, countPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueModelPaginationModelResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesGet(pageNumber, countPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueDetailModelResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {JoinLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesJoinPut(body?: JoinLeagueCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesJoinPut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [countPerPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesMatchesGet(pageNumber?: number, countPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchModelPaginationModelResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesMatchesGet(pageNumber, countPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesMatchesLatestPerLeagueGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchModelListResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesMatchesLatestPerLeagueGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesMatchesLatestPerLeagueUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchModelListResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesMatchesLatestPerLeagueUserIdGet(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesMatchesMatchIdGet(matchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchModelResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesMatchesMatchIdGet(matchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} matchId 
         * @param {UpdateMatchResultCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesMatchesMatchIdPut(matchId: string, body?: UpdateMatchResultCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesMatchesMatchIdPut(matchId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MovePlayerToLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesMovePlayerToLeaguePut(body?: MovePlayerToLeagueCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesMovePlayerToLeaguePut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesOngoingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueDetailModelListResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesOngoingGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesPost(body?: CreateLeagueCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesPut(body?: UpdateLeagueCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesPut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RemovePlayersFromPlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesRemovePlayersFromPlayoffsGamePut(body?: RemovePlayersFromPlayoffsGameCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesRemovePlayersFromPlayoffsGamePut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SetPlayoffsRoundPeriodCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesSetRoundPeriodToPlayoffsGamePut(body?: SetPlayoffsRoundPeriodCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesSetRoundPeriodToPlayoffsGamePut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SubmitMatchResultCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesSubmitMatchResultPut(body?: SubmitMatchResultCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesSubmitMatchResultPut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLeagueModelListResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesUserGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeaguesUserIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLeagueModelListResponseResult>> {
            const localVarAxiosArgs = await LeaguesApiAxiosParamCreator(configuration).apiLeaguesUserIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LeaguesApi - factory interface
 * @export
 */
export const LeaguesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [city] 
         * @param {string} [season] 
         * @param {string} [leagueType] 
         * @param {string} [skillLevel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesActiveGet(userId?: string, city?: string, season?: string, leagueType?: string, skillLevel?: string, options?: any): AxiosPromise<LeagueModelListResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesActiveGet(userId, city, season, leagueType, skillLevel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangeMatchResultCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesAddMatchChangeRequestPut(body?: ChangeMatchResultCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesAddMatchChangeRequestPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddPlayersToPlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesAddPlayersToPlayoffsGamePut(body?: AddPlayersToPlayoffsGameCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesAddPlayersToPlayoffsGamePut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddTop8PlayersToPlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesAddTop8playersToPlayoffsGamePut(body?: AddTop8PlayersToPlayoffsGameCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesAddTop8playersToPlayoffsGamePut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesChangeLeagueStatusPut(options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesChangeLeagueStatusPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneratePlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesGeneratePlayoffsGamePut(body?: GeneratePlayoffsGameCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesGeneratePlayoffsGamePut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [countPerPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesGet(pageNumber?: number, countPerPage?: number, options?: any): AxiosPromise<LeagueModelPaginationModelResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesGet(pageNumber, countPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesIdDelete(id: string, options?: any): AxiosPromise<boolean> {
            return LeaguesApiFp(configuration).apiLeaguesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesIdGet(id: string, options?: any): AxiosPromise<LeagueDetailModelResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {JoinLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesJoinPut(body?: JoinLeagueCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesJoinPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [countPerPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesGet(pageNumber?: number, countPerPage?: number, options?: any): AxiosPromise<MatchModelPaginationModelResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesMatchesGet(pageNumber, countPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesLatestPerLeagueGet(options?: any): AxiosPromise<MatchModelListResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesMatchesLatestPerLeagueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesLatestPerLeagueUserIdGet(userId: string, options?: any): AxiosPromise<MatchModelListResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesMatchesLatestPerLeagueUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesMatchIdGet(matchId: string, options?: any): AxiosPromise<MatchModelResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesMatchesMatchIdGet(matchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} matchId 
         * @param {UpdateMatchResultCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMatchesMatchIdPut(matchId: string, body?: UpdateMatchResultCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesMatchesMatchIdPut(matchId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MovePlayerToLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesMovePlayerToLeaguePut(body?: MovePlayerToLeagueCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesMovePlayerToLeaguePut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesOngoingGet(options?: any): AxiosPromise<LeagueDetailModelListResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesOngoingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesPost(body?: CreateLeagueCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLeagueCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesPut(body?: UpdateLeagueCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemovePlayersFromPlayoffsGameCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesRemovePlayersFromPlayoffsGamePut(body?: RemovePlayersFromPlayoffsGameCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesRemovePlayersFromPlayoffsGamePut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPlayoffsRoundPeriodCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesSetRoundPeriodToPlayoffsGamePut(body?: SetPlayoffsRoundPeriodCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesSetRoundPeriodToPlayoffsGamePut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitMatchResultCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesSubmitMatchResultPut(body?: SubmitMatchResultCommand, options?: any): AxiosPromise<BooleanResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesSubmitMatchResultPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesUserGet(options?: any): AxiosPromise<UserLeagueModelListResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeaguesUserIdGet(id: string, options?: any): AxiosPromise<UserLeagueModelListResponseResult> {
            return LeaguesApiFp(configuration).apiLeaguesUserIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeaguesApi - object-oriented interface
 * @export
 * @class LeaguesApi
 * @extends {BaseAPI}
 */
export class LeaguesApi extends BaseAPI {
    /**
     * 
     * @param {string} [userId] 
     * @param {string} [city] 
     * @param {string} [season] 
     * @param {string} [leagueType] 
     * @param {string} [skillLevel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesActiveGet(userId?: string, city?: string, season?: string, leagueType?: string, skillLevel?: string, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesActiveGet(userId, city, season, leagueType, skillLevel, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {ChangeMatchResultCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesAddMatchChangeRequestPut(body?: ChangeMatchResultCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesAddMatchChangeRequestPut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {AddPlayersToPlayoffsGameCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesAddPlayersToPlayoffsGamePut(body?: AddPlayersToPlayoffsGameCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesAddPlayersToPlayoffsGamePut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {AddTop8PlayersToPlayoffsGameCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesAddTop8playersToPlayoffsGamePut(body?: AddTop8PlayersToPlayoffsGameCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesAddTop8playersToPlayoffsGamePut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesChangeLeagueStatusPut(options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesChangeLeagueStatusPut(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {GeneratePlayoffsGameCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesGeneratePlayoffsGamePut(body?: GeneratePlayoffsGameCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesGeneratePlayoffsGamePut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [countPerPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesGet(pageNumber?: number, countPerPage?: number, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesGet(pageNumber, countPerPage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesIdDelete(id: string, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesIdGet(id: string, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {JoinLeagueCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesJoinPut(body?: JoinLeagueCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesJoinPut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [countPerPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesMatchesGet(pageNumber?: number, countPerPage?: number, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesMatchesGet(pageNumber, countPerPage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesMatchesLatestPerLeagueGet(options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesMatchesLatestPerLeagueGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesMatchesLatestPerLeagueUserIdGet(userId: string, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesMatchesLatestPerLeagueUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} matchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesMatchesMatchIdGet(matchId: string, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesMatchesMatchIdGet(matchId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} matchId 
     * @param {UpdateMatchResultCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesMatchesMatchIdPut(matchId: string, body?: UpdateMatchResultCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesMatchesMatchIdPut(matchId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {MovePlayerToLeagueCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesMovePlayerToLeaguePut(body?: MovePlayerToLeagueCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesMovePlayerToLeaguePut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesOngoingGet(options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesOngoingGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {CreateLeagueCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesPost(body?: CreateLeagueCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateLeagueCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesPut(body?: UpdateLeagueCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesPut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {RemovePlayersFromPlayoffsGameCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesRemovePlayersFromPlayoffsGamePut(body?: RemovePlayersFromPlayoffsGameCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesRemovePlayersFromPlayoffsGamePut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {SetPlayoffsRoundPeriodCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesSetRoundPeriodToPlayoffsGamePut(body?: SetPlayoffsRoundPeriodCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesSetRoundPeriodToPlayoffsGamePut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {SubmitMatchResultCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesSubmitMatchResultPut(body?: SubmitMatchResultCommand, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesSubmitMatchResultPut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesUserGet(options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesUserGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaguesApi
     */
    public apiLeaguesUserIdGet(id: string, options?: any) {
        return LeaguesApiFp(this.configuration).apiLeaguesUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}
