
















































import router from "@/router";
import Vue from "vue";
import Index from "../../components/Index.vue";
import AdminNavigation from "../../components/AdminNavigation.vue";
export default Vue.extend({
  name: "Home",
  data() {
    return {
      hello: "",
    };
  },
  computed: {
    user: function () {
      return this.$store.state.user;
    },
  },
  watch: {
    user(newUser) {
      if (!newUser.firstName) {
        router.push({ path: "/dashboard" });
      }
    },
  },
  mounted() {
    this.$store.dispatch("UpdateErrorMessageDialog", {});
    this.$store.dispatch("loadUser");
  },
  components: {
    Index,
    AdminNavigation,
  },
});
