






























































import { MatchModel } from "@/api_client/models";
import badmintonApi from "@/plugins/Api";
import MatchResultChangeRequest from "../components/MatchResultChangeRequest.vue";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      matches: [] as MatchModel[],
      isShowChangeMatchResultDialog: false,
      selectedMatch: null as MatchModel | null,
      matchesCurrentPageNumber: 1,
      matchesPageLength: 0,
      matchesCountPerPage: 10,
    };
  },
  methods: {
    loadUserMatches() {
      badmintonApi.LeaguesApi?.apiLeaguesMatchesGet(
        this.matchesCurrentPageNumber,
        this.matchesCountPerPage
      ).then(
        (response) => {
          this.matches = response.data.value.data;
          this.matchesPageLength=response.data.value.totalPage;
        }
      );
    },
    showChangeMatchResultDialog(match: MatchModel) {
      this.selectedMatch = match;
      this.isShowChangeMatchResultDialog = true;
    },
  },
  mounted() {
    this.loadUserMatches();
  },
  watch: {
    matchesCurrentPageNumber() {
      this.loadUserMatches();
    },
  },
  components: {
    MatchResultChangeRequest,
  },
});
