import Vue from 'vue'
import Vuex from 'vuex'
import { UserModel } from '@/api_client/models';
import badmintonApi from '@/plugins/Api';
import { ErrorMessageDialog } from '@/models';
import router from '@/router';

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    user: {} as UserModel,
    unauthorized: true,
    isShowLoading: false,
    errorMessageDialog: {} as ErrorMessageDialog,
    SkillLevelMapping: [
      { SkillLevel: "A", DisplayValue: "A" },
      { SkillLevel: "B", DisplayValue: "B" },
      { SkillLevel: "C", DisplayValue: "C" },
      { SkillLevel: "D", DisplayValue: "D" },
    ],
    LeagueTypeMapping: [
      { LeagueType: "MenSingle", DisplayValue: "Men's Singles" },
      { LeagueType: "MenDouble", DisplayValue: "Men's Doubles" },
      { LeagueType: "WomenSingle", DisplayValue: "Women's Singles" },
      { LeagueType: "WomenDouble", DisplayValue: "Women's Doubles" },
      { LeagueType: "MixedDouble", DisplayValue: "Mixed Doubles" },
    ]
  },
  mutations: {
    UpdateUser(state: any, user: UserModel) {
      state.user = user;
      state.unauthorized = false;
    },
    UpdateUserProfileImg(state: any, imgUrl: string) {
      state.user.picture = imgUrl;
    },
    UpdateUnreadMessageNumber(state: any, nunmber: number) {
      state.user.unreadMessageCount = nunmber;
    },
    UpdateAdminUnreadMessageNumber(state: any, nunmber: number) {
      state.user.adminUnreadMessageCount = nunmber;
    },
    UpdateLoadingStatus(state: any, isShowLoading: boolean) {
      state.isShowLoading = isShowLoading;
    },
    UpdateErrorMessageDialog(state: any, dialog: ErrorMessageDialog) {
      state.errorMessageDialog = dialog;
    }
  },
  actions: {
    UpdateUserProfileImg(context: any, imgUrl: string) {
      context.commit('UpdateUserProfileImg', imgUrl)
    },
    UpdateUnreadMessageNumber(context: any, nunmber: number) {
      context.commit('UpdateUnreadMessageNumber', nunmber)
    },
    UpdateAdminUnreadMessageNumber(context: any, nunmber: number) {
      context.commit('UpdateAdminUnreadMessageNumber', nunmber)
    },
    loadUser(context: any) {
      badmintonApi.UsersApi?.apiUsersBasicInfoGet().then((response) => {
        context.commit('UpdateUser', response.data.value);
      }, (error) => {

        if (router.currentRoute.path == "/dashboard" && error.response.status == 401) {
          router.push({ path: "login" });
        }

      }
      );
    },
    UpdateLoadingStatus(context: any, isShowLoading: boolean) {
      context.commit('UpdateLoadingStatus', isShowLoading)
    },
    UpdateErrorMessageDialog(context: any, dialog: ErrorMessageDialog) {
      context.commit('UpdateErrorMessageDialog', dialog)
    }
  },
  modules: {
  }
})
