


































































































import router from "@/router";
import Vue from "vue";
export default Vue.extend({
  name: "Navigation",
  data() {
    return {
      drawer: false,
      userName: "",
      userImgurl: "",
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      router.push({ path: "/login" });
    },
  },
  computed: {
    isSm() {
      const { sm, xs } = this.$vuetify.breakpoint;
      var isSM = sm || xs;
      return isSM;
    },
    user() {
      return this.$store.state.user;
    },
    items() {
      const items = [
        {
          title: "Home",
          route: "/",
          icon: "mdi-home",
        },
        {
          title: "Notifications",
          route: "/admin/notifications",
          icon: "mdi-message-badge-outline",
          isShowBadge: true,
        },
        {
          title: "Users",
          route: "/admin/user-management",
          icon: "mdi-account-search",
        },
        {
          title: "Leagues",
          route: "/admin/league-management",
          icon: "mdi-account-group",
        },
      ];
      return items;
    },
  },
});
