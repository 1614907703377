












import Vue from "vue";
import badmintonApi from "./plugins/Api";

export default Vue.extend({
  name: "App",

  data: () => ({
    //
  }),
  mounted() {
    var token = this.$route.query.token as string;
    if (token) {
      badmintonApi.Axios.defaults.headers.common["Authorization"] =
        "Bearer " + token;
      localStorage.setItem("token", token);
    }
  },
});
