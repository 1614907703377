























































import Vue from "vue";

export default Vue.extend({
  props: {
    isAlertDialog: Boolean,
    isConfirmDialog: Boolean,
    closeCallback: Function,
    isShowLoading: Boolean,
    maxWidth: Number,
    isShowDialog: Boolean,
    title: String,
    describe: String,
    confirmCallback: Function,
    cancelCallback: Function,
  },
  data() {
    return {
      shown: this.isShowDialog
    }
  },
  watch: {
    isShowDialog(val) {
      this.shown = val; 
    }
  }
});
