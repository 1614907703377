

















































































































import badmintonApi from "@/plugins/Api";
import router from "@/router";
import { UpdatePasswordCommand } from "@/api_client/models";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      email: "",
      isGetingPassCode: false,
      isResettingPassword: false,
      isPassCodeFormValid: false,
      isResetPasswordFormValid: false,
      isShowResetPasswordForm: true,
      showPassCodeFormSuccessMessage:false,
      showPassCodeFormErrorMessage: false,
      passCodeFormErrorMessage: "",
      showResetPasswordFormErrorMessage: false,
      resetPasswordFormErrorMessage: "",
      emailRules: [
        (v: any) => !!v || "E-mail is required",
        (v: any) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [(v: any) => !!v || "Password is required"],
      passcode: "",
      showPassword: false,
      showConfirmPassword: false,
      passCodeRules: [
        (v: any) => !!v || "Pass Code is required",
        (v: any) => !v || v.length == 6 || "Pass Code must be 6 characters",
      ],
      confirmPassword: "",
      confirmPasswordRules: [(v: any) => !!v || "Confirm password is required"],
    };
  },

  methods: {
    checkConfirmPassword(confirmPassword: any) {
      return (
        confirmPassword == this.password ||
        "Confirm password must same as password."
      );
    },
    getPassCode() {
      var form = this.$refs?.passCodeForm as any;
      if (form.validate()) {
        this.isGetingPassCode = true;
        this.showPassCodeFormErrorMessage = false;
        badmintonApi.UsersApi?.apiEmailGetPassCodeGet(this.email).then(
          (response) => {
            this.isGetingPassCode = false;
            this.isShowResetPasswordForm = true;
            this.showPassCodeFormSuccessMessage=true;
          },
          (error) => {
            this.isGetingPassCode = false;
            this.showPassCodeFormErrorMessage = true;
            this.passCodeFormErrorMessage = error.response.data.Message;
          }
        );
      }
    },
    resetPassword() {
      var passCodeForm = this.$refs?.passCodeForm as any;
      var form = this.$refs?.resetPasswordForm as any;
      if (passCodeForm.validate() && form.validate()) {
        this.isResettingPassword = true;
        this.showResetPasswordFormErrorMessage = false;
        var resetPasswordCommand = {
          email: this.email,
          password: this.password,
          passCode: this.passcode,
        } as UpdatePasswordCommand;
        badmintonApi.UsersApi?.apiPasswordPut(resetPasswordCommand).then(
          (response) => {
            this.isResettingPassword = false;
            router.push({ path: "/login" });
          },
          (error) => {
            this.isResettingPassword = false;
            this.showResetPasswordFormErrorMessage = true;
            this.resetPasswordFormErrorMessage = error.response.data.Message;
          }
        );
      }
    },
  },
});
