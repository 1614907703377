



























































































import { UpdateUserRoleCommand, UserModel } from "@/api_client/models";
import badmintonApi from "@/plugins/Api";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      users: [] as UserModel[],
      isShowDialog: false,
      isShowLoading: false,
      editingUser: null as UserModel | null,
      selectedRole: "",
      currentPageNumber: 1,
      countPerPage:10,
      pageLength: 0,
    };
  },
  methods: {
    loadUsers() {
      badmintonApi.UsersApi?.apiUsersGet(this.currentPageNumber,this.countPerPage).then(
        (response) => {
          this.users = response.data.value.data;
          this.pageLength = response.data.value.totalPage;
        }
      );
    },
    showEditDialog(user: UserModel) {
      this.editingUser = user;
      this.selectedRole =
        this.editingUser.roles.length > 0 ? this.editingUser.roles[0] : "User";
      this.isShowDialog = true;
    },
    editUser() {
      this.isShowLoading = true;
      var command = {
        userId: this.editingUser?.id,
        roles: [this.selectedRole],
      } as UpdateUserRoleCommand;
      badmintonApi.UsersApi?.apiUsersChangeRolesPut(command).then(
        (response) => {
          this.isShowLoading = false;
          this.isShowDialog = false;
          this.loadUsers();
        },
        (error) => {
          this.isShowLoading = false;
        }
      );
    },
  },
  watch: {
    currentPageNumber() {
      this.loadUsers();
    },
  },
  mounted() {
    this.loadUsers();
  },
});
