


















































import { ChangeMatchResultCommand } from "@/api_client/models";
import badmintonApi from "@/plugins/Api";
import Modal from "../components/Modal.vue"
import Vue from "vue";
export default Vue.extend({
  props: {
    match: Object,
    isShowDialog: Boolean,
    successCallback: Function,
    cancelCallback: Function,
  },
  data() {
    return {
      description: "",
      isSendingRequest: false,
      isShowSuccessDialog: false,
    };
  },
  methods: {
    sendRequest() {
      var command = {
        matchId: this.match.id,
        content: this.description,
      } as ChangeMatchResultCommand;
      this.isSendingRequest = true;
      badmintonApi.LeaguesApi?.apiLeaguesAddMatchChangeRequestPut(command).then(
        (response) => {
          this.isSendingRequest = false;
          this.isShowSuccessDialog = true;
        },
        (error) => {
          this.isSendingRequest = false;
        }
      );
    },
    closeSuccessDialog() {
      this.isShowSuccessDialog=false;
      this.successCallback();
    },
  },
  components:{
    Modal
  }
});
