






















































import badmintonApi from "@/plugins/Api";
import { MessageUIModel } from "../../models/index";
import { SetMessageToReadCommand } from "@/api_client/models";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      messages: [] as MessageUIModel[],
    };
  },
  methods: {
    MardAsRead(message: any) {
      var command = {
        messageId: message.id,
      } as SetMessageToReadCommand;
      message.isMarkingAsRead = true;
      badmintonApi.MessagesApi?.apiMessagesReadPut(command).then(
        (response) => {
          message.isMarkingAsRead = false;
          this.loadMessage();
        },
        (error) => {
          message.isMarkingAsRead = false;
        }
      );
    },
    loadMessage() {
      badmintonApi.MessagesApi?.apiMessagesAdminGet().then(
        (response) => {
          response.data.value.map((a: MessageUIModel) => {
            a.isRejecting = false;
            a.isAccepting = false;
            a.isMarkingAsRead = false;
          });

          this.messages = response.data.value as MessageUIModel[];

          this.$store.dispatch(
            "UpdateAdminUnreadMessageNumber",
            this.messages.filter((a) => !a.isRead).length
          );
        }
      );
    },
  },
  mounted() {
    this.loadMessage();
  },
});
