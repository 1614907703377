
















































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Modal from "../components/Modal.vue";
import JoinLeague from "../components/JoinLeague.vue";
import TeamRequest from "../components/TeamRequest.vue";
import badmintonApi from "@/plugins/Api";
import {
  AddPlayersToPlayoffsGameCommand,
  AddTop8PlayersToPlayoffsGameCommand,
  GeneratePlayoffsGameCommand,
  LeagueDetailModel,
  MovePlayerToLeagueCommand,
  PlayoffsMatchModel,
  RemovePlayersFromPlayoffsGameCommand,
  RoundPeriodModel,
  SetPlayoffsRoundPeriodCommand,
} from "@/api_client/models";
import { UserUIModel, TeamUIModel } from "@/models";
import { MessageUIModel } from "../models/index";
export default Vue.extend({
  data() {
    return {
      isShowTeamRequestSuccessDialog: false,
      isShowMovePlayerToAnotherLeagueDialog: false,
      isShowPutTop8PlayersToPlayoffsGameDialog: false,
      isPuttingTop8PlayersToPlayoffsGame: false,
      isGeneratingPlayoffsSchema: false,
      isShowJoinLeagueSuccessDialog: false,
      isShowSetRoundPeriodDialog: false,
      isShowDialog: false,
      league: null as LeagueDetailModel | null,
      isSendingJoinRequest: false,
      isSendingTeamRequest: false,
      isMovingPlayerToAnotherLeague: false,
      matchesCurrentPageNumber: 1,
      matchesPageLength: 0,
      matchesCountPerPage: 10,
      matchesInCurrentPage: [],
      isShowAddPlayerToPlayoffsDialog: false,
      playersNotInLeague: [] as UserUIModel[],
      teamsNotInLeague: [] as TeamUIModel[],
      selectedRound: null as RoundPeriodModel | null,
      startMenu: false,
      endMenu: false,
      startDate: "",
      endDate: "",
      isValid: false,
      isMovePlayerFormValid: false,
      movePlayerId: "",
      selectedLeague: null as any,
      ongoingLeagues: null,
      isSavingRoundPeriod: false,
      matchStyle: {
        border: '0',
        width: '100%',
        height: '60px',
        position: 'relative'
      },
      thirdPlayoffsMatch: null as PlayoffsMatchModel | null,
    };
  },
  methods: {
    hideDeleteModal() {
      this.isShowDialog = false;
    },
    showSetRoundPeriodDialog(round: any) {
      this.selectedRound = round;
      this.startDate = round.startDate?.substr(0, 10);
      this.endDate = round.endDate?.substr(0, 10);
      this.isShowSetRoundPeriodDialog = true;
    },
    updateRoundPeriod() {
      var command = {
        leagueId: this.league?.id,
        roundIndex: this.selectedRound?.roundIndex,
        starDate: this.startDate,
        endDate: this.endDate,
      } as SetPlayoffsRoundPeriodCommand;
      this.isSavingRoundPeriod = true;
      badmintonApi.LeaguesApi?.apiLeaguesSetRoundPeriodToPlayoffsGamePut(
        command
      ).then(
        (response) => {
          this.isSavingRoundPeriod = false;
          this.isShowSetRoundPeriodDialog = false;
          this.loadLeagueDetail();
        },
        (error) => {
          this.isSavingRoundPeriod = false;
        }
      );
    },
    managePlayoffsGame() {
      if (!this.league?.playoffs || this.league?.playoffs.players.length == 0) {
        this.isShowPutTop8PlayersToPlayoffsGameDialog = true;
      }
    },
    generatePlayoffsSchema() {
      var command = {
        leagueId: this.league?.id,
      } as GeneratePlayoffsGameCommand;
      this.isGeneratingPlayoffsSchema = true;
      badmintonApi.LeaguesApi?.apiLeaguesGeneratePlayoffsGamePut(command).then(
        (response) => {
          this.isGeneratingPlayoffsSchema = false;
          this.loadLeagueDetail();
        },
        (error) => {
          this.isGeneratingPlayoffsSchema = false;
        }
      );
    },
    putTop8PlayersToPlayoffsGame() {
      var command = {
        leagueId: this.league?.id,
      } as AddTop8PlayersToPlayoffsGameCommand;
      this.isPuttingTop8PlayersToPlayoffsGame = true;
      badmintonApi.LeaguesApi?.apiLeaguesAddTop8playersToPlayoffsGamePut(
        command
      ).then(
        (response) => {
          this.isPuttingTop8PlayersToPlayoffsGame = false;
          this.isShowPutTop8PlayersToPlayoffsGameDialog = false;
          this.loadLeagueDetail();
        },
        (error) => {
          this.isPuttingTop8PlayersToPlayoffsGame = false;
        }
      );
    },
    removeFromPlayoffsGame(player: UserUIModel | TeamUIModel) {
      var command = {
        leagueId: this.league?.id,
        playerIds: [player.id],
      } as RemovePlayersFromPlayoffsGameCommand;
      player.isRemovingFromPlayoffsGame = true;
      badmintonApi.LeaguesApi?.apiLeaguesRemovePlayersFromPlayoffsGamePut(
        command
      ).then(
        (response) => {
          player.isRemovingFromPlayoffsGame = false;
          this.loadLeagueDetail();
        },
        (error) => {
          player.isRemovingFromPlayoffsGame = false;
        }
      );
    },
    addToPlayoffsGame(player: UserUIModel | TeamUIModel) {
      var command = {
        leagueId: this.league?.id,
        playerIds: [player.id],
      } as AddPlayersToPlayoffsGameCommand;
      player.isAddingToPlayoffsGame = true;
      badmintonApi.LeaguesApi?.apiLeaguesAddPlayersToPlayoffsGamePut(
        command
      ).then(
        (response) => {
          this.loadLeagueDetail();
        },
        (error) => {
          player.isAddingToPlayoffsGame = false;
        }
      );
    },
    loadLeagueDetail() {
      badmintonApi.LeaguesApi?.apiLeaguesIdGet(this.$route.params.id).then(
        (response) => {
          this.league = response.data.value;
          this.matchesPageLength = Math.ceil(
            this.league?.matches.length / this.matchesCountPerPage
          );
          this.setMatchesForCurrentPage();
          if (
            this.league?.playoffs &&
            this.league.playoffs.players.length > 0
          ) {
            var playersInLeague = this.league.playoffs.players;
            if (this.league.isDoubleLeague) {
              this.league.teams = this.league.teams.map((a: any) => {
                return {
                  id: a.id,
                  isAddingToPlayoffsGame: false,
                  isRemovingFromPlayoffsGame: false,
                  players: a.players,
                };
              });
              this.teamsNotInLeague = this.league.teams.filter((a: any) =>
                playersInLeague.every((b: any) => b.playerId != a.id)
              );
            } else {
              this.league.players = this.league.players.map((a: any) => {

                return {
                  id: a.id,
                  picture: a.picture,
                  fullName: a.fullName,
                  city: a.city,
                  skillLevel: a.skillLevel,
                  isPaidInLeague: a.isPaidInLeague,
                  isAddingToPlayoffsGame: false,
                  isRemovingFromPlayoffsGame: false,
                };
              });
              this.playersNotInLeague = this.league.players.filter((a: any) =>
                playersInLeague.every((b: any) => b.playerId != a.id)
              );
            }
            this.thirdPlayoffsMatch = this.league.playoffs.thirdPlayoffsMatch;
          }
        }
      );
    },
    setMatchesForCurrentPage() {
      var offset =
        (this.matchesCurrentPageNumber - 1) * this.matchesCountPerPage;
      var array = this.league?.matches;
      this.matchesInCurrentPage =
        offset + this.matchesCountPerPage >= array.length
          ? this.league?.matches.slice(offset, array.length)
          : array.slice(offset, offset + this.matchesCountPerPage);
    },
    showMovePlayerDailog() {
      this.loadAllActiveLeagues();
      this.isShowMovePlayerToAnotherLeagueDialog = true;
    },
    movePlayerToAnotherLeague() {
      this.isMovingPlayerToAnotherLeague = true;
      var command = {
        leagueId: this.selectedLeague.id,
        userId: this.movePlayerId,
      } as MovePlayerToLeagueCommand;
      badmintonApi.LeaguesApi?.apiLeaguesMovePlayerToLeaguePut(command).then(
        (response) => {
          this.isShowMovePlayerToAnotherLeagueDialog = false;
          this.isMovingPlayerToAnotherLeague = true;
        },
        (error) => {
          this.isMovingPlayerToAnotherLeague = false;
        }
      );
    },
    loadAllActiveLeagues() {
      if (!this.ongoingLeagues) {
        badmintonApi.LeaguesApi?.apiLeaguesActiveGet(
          undefined,
          "All",
          "All",
          "All",
          "All"
        ).then((response) => {
          this.ongoingLeagues = response.data.value.filter(
            (a: any) => a.id != this.league?.id
          );
        });
      }
    },
    getLeagueTypeDisplayName(type: string) {
      var xx = this.$store.state.LeagueTypeMapping.find(
        (b: any) => b.LeagueType == type
      )?.DisplayValue;
      return xx;
    },
    getSkillLevelDisplayName(skillLevel: string) {
      return this.$store.state.SkillLevelMapping.find(
        (b: any) => b.SkillLevel == skillLevel
      )?.DisplayValue;
    },
    directToDashboard() {
      badmintonApi.MessagesApi?.apiMessagesGet().then(
        (response) => {
          let messages = response.data.value as MessageUIModel[];
          let unreadMessages = messages.filter((a) => !a.isRead);
          this.$store.dispatch(
            "UpdateUnreadMessageNumber",
            unreadMessages.length
          );
        }
      );

      this.$router.push({ path: '/dashboard' })
    }
  },
  watch: {
    matchesCurrentPageNumber() {
      this.setMatchesForCurrentPage();
    },
  },
  mounted() {
    this.loadLeagueDetail();
  },
  components: {
    Modal,
    JoinLeague,
    TeamRequest,
  },
});
