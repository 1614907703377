enum LeagueType {
    MenSingle = 1,
    MenDouble = 2,
    WomenSingle = 3,
    WomenDouble = 4,
    MixedDouble = 5
}
enum SkillLevel {
    A = 1,
    B = 2,
    C = 3,
    D = 4
}
enum LeagueStatus {
    Draft = 1,
    Active = 2,
    Start = 3,
    Complete = 4
}
enum City {
    Boston = 1,
    Chicago = 2,
    Purdue = 3,
    Virginia = 4
}
enum Season {
    Fall = 1,
    Winter = 2,
    Spring = 3,
    Summer = 4
}
function getEnumValues(enumObj: any) {
    var names = [];
    for (var item in enumObj) {
        if (typeof enumObj[item] === "number") names.push(item);
    }
    return names;
}

export { LeagueType, SkillLevel, LeagueStatus,City,Season, getEnumValues }
