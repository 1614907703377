





















































































































































































































































import {
  LeagueDetailModel,
  MatchModel,
  PlayoffsMatchModel,
  SubmitMatchResultCommand,
  TeamModel,
  UpdateMatchResultCommand,
} from "@/api_client/models";
import badmintonApi from "@/plugins/Api";
import Modal from "../components/Modal.vue";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isValid: false,
      isSaving: false,
      isUpdating: false,
      isShowSuccessDialog: false,
      name: "test",
      valid: false,
      player1: "",
      player2: "",
      scores: [] as number[],
      set1Player1Score: 0,
      set1Player2Score: 0,
      set2Player1Score: 0,
      set2Player2Score: 0,
      set3Player1Score: 0,
      set3Player2Score: 0,
      location: "",
      duration: "",
      other: "",
      ongoingLeagues: [] as LeagueDetailModel[],
      selectedLeague: null as LeagueDetailModel | null,
      isEdit: false,
      editMatch: null as MatchModel | null,
      matchId: "",
      messageId: "",
      playoffsMatch: null as PlayoffsMatchModel | null,
      isPlayoffsMatch: false,
      hasPlayoffsMatch: false
    };
  },
  methods: {
    create() {
      this.isSaving = true;
      var command = {
        leagueId: this.selectedLeague?.id,
        playoffsMatchId: this.playoffsMatch?.id,
        player1: this.player1,
        player2: this.player2,
        location: this.location,
        durationInMinutes: this.duration,
        description: this.other,
        isPlayoffsMatch: this.isPlayoffsMatch,
        sets: [
          {
            setIndex: 1,
            player1Score: this.set1Player1Score,
            player2Score: this.set1Player2Score,
          },
          {
            setIndex: 2,
            player1Score: this.set2Player1Score,
            player2Score: this.set2Player2Score,
          },
        ],
      } as SubmitMatchResultCommand;
      if (this.set3Player1Score || this.set3Player2Score) {
        command.sets.push({
          setIndex: 3,
          player1Score: this.set3Player1Score,
          player2Score: this.set3Player2Score,
        });
      }

      badmintonApi.LeaguesApi?.apiLeaguesSubmitMatchResultPut(command).then(
        (response) => {
          this.isSaving = false;
          this.isShowSuccessDialog = true;
          this.$router.go(-1);
        },
        (error) => {
          this.isSaving = false;
        }
      );
      
    },
    update() {
      this.isUpdating = true;
      var command = {
        id: this.matchId,
        leagueId: this.selectedLeague?.id,
        player1: this.player1,
        player2: this.player2,
        location: this.location,
        durationInMinutes: this.duration,
        description: this.other,
        messageId: this.messageId,
        sets: [
          {
            setIndex: 1,
            player1Score: this.set1Player1Score,
            player2Score: this.set1Player2Score,
          },
          {
            setIndex: 2,
            player1Score: this.set2Player1Score,
            player2Score: this.set2Player2Score,
          },
        ],
      } as UpdateMatchResultCommand;
      if (this.set3Player1Score || this.set3Player2Score) {
        command.sets.push({
          setIndex: 3,
          player1Score: this.set3Player1Score,
          player2Score: this.set3Player2Score,
        });
      }
      badmintonApi.LeaguesApi?.apiLeaguesMatchesMatchIdPut(
        this.matchId,
        command
      ).then(
        (response) => {
          this.isUpdating = false;
          this.isShowSuccessDialog = true;
          this.$router.go(-1);
        },
        (error) => {
          this.isUpdating = false;
        }
      );
    },
    save() {
      var form = this.$refs.form as any;
      if (!form.validate()) return;
      this.isShowSuccessDialog = true;
    },
    confirmCallback() {
      this.isShowSuccessDialog = false;
      if(this.matchId) {
        this.update();
      }else{
        this.create();
      }
    },
    cancelCallback() {
      this.isShowSuccessDialog = false;
    },
    loadOngoingLeague() {
      return badmintonApi.LeaguesApi?.apiLeaguesOngoingGet().then(
        (response) => {
          this.ongoingLeagues = response.data.value;
          if (!this.ongoingLeagues.length) return;

          this.selectedLeague = this.ongoingLeagues[0];
          this.setPlayer1();
          
        },
        (error) => {}
      );
    },
    setPlayer1() {
      if (this.selectedLeague && !this.isEdit) {
        if (this.selectedLeague.isDoubleLeague) {
          var teams = this.selectedLeague.teams as TeamModel[];
          this.player1 = teams.find(
            (a) =>
              a.players.filter((b: any) => b.id == this.$store.state.user.id)
                .length > 0
          )?.id;
        } else {
          this.player1 = this.$store.state.user.id;
        }
      }
    },
    setEditData() {
      if (!this.editMatch || !this.ongoingLeagues.length) return;
       
      this.selectedLeague = this.ongoingLeagues.find(
        (a) => a.id == this.editMatch?.leagueId
      ) as LeagueDetailModel;

      let _this = this;
      setTimeout(function(){
        _this.player1 = _this.editMatch?.player1;
        _this.player2 = _this.editMatch?.player2;
        _this.duration = _this.editMatch?.durationInMinutes;
        _this.location = _this.editMatch?.location;
        _this.other = _this.editMatch?.description;
        _this.set1Player1Score = _this.editMatch?.sets[0].player1Score;
        _this.set1Player2Score = _this.editMatch?.sets[0].player2Score;
        _this.set2Player1Score = _this.editMatch?.sets[1].player1Score;
        _this.set2Player2Score = _this.editMatch?.sets[1].player2Score;
        if (_this.editMatch?.sets.length == 3) {
          _this.set3Player1Score = _this.editMatch?.sets[2].player1Score;
          _this.set3Player2Score = _this.editMatch?.sets[2].player2Score;
        }
      },0);
    },
    initializeData(){
      this.isPlayoffsMatch = false;
      this.player2 = '';
      this.duration = '';
      this.location = '';
      this.other = '';
      this.set1Player1Score = 0;
      this.set1Player2Score = 0;
      this.set2Player1Score = 0;
      this.set2Player2Score = 0;
      this.set3Player1Score = 0;
      this.set3Player2Score = 0;
    },
    loadMatch() {
      badmintonApi.LeaguesApi?.apiLeaguesMatchesMatchIdGet(this.matchId).then(
        (response) => {
          console.log(response.data.value);
          this.editMatch = response.data.value;
          this.setEditData();
        },
        (error) => {}
      );
    },
  },
  watch: {
    user(user) {
      this.setPlayer1();
    },
    isPlayoffsMatch() {
      if (this.isPlayoffsMatch) {
        this.player2 =
          this.playoffsMatch?.leftPlayer != this.player1
            ? this.playoffsMatch?.leftPlayer
            : this.playoffsMatch?.rightPlayer;
      } else {
        this.player2 = "";
      }
    },
    selectedLeague() {
      var playerId = this.selectedLeague?.isDoubleLeague
        ? this.selectedLeague?.teams.find((a: any) =>
            a.players.some((b: any) => b.id == this.$store.state.user.id)
          )?.id
        : this.$store.state.user.id;
      this.playoffsMatch = this.selectedLeague?.playoffs?.playoffsMatches?.find(
        (a: any) =>
          a.leftPlayer &&
          a.rightPlayer &&
          !a.match &&
          (a.leftPlayer == playerId || a.rightPlayer == playerId)
      );

      this.hasPlayoffsMatch = this.selectedLeague?.playoffs != null;
      this.setPlayer1();
      this.initializeData();
    },
  },
  mounted() {
    for (let index = 0; index < 31; index++) {
      this.scores.push(index);
    }
    this.loadOngoingLeague()?.then((response)=>{
      if (!this.$route.params.matchId) return;
       this.isEdit = true;
      this.matchId = this.$route.params.matchId;
      this.messageId = this.$route.params.messageId;
      this.loadMatch();
    }, (error)=>{})
  },
  computed: {
    disabledField(): boolean {
      return !this.isEdit && !this.isPlayoffsMatch && this.hasPlayoffsMatch;
    }
  },
  components: {
    Modal,
  },
});
