


















































































































































































































































import Modal from "../../components/Modal.vue";
import Vue from "vue";
import {
  LeagueType,
  getEnumValues,
  LeagueStatus,
  SkillLevel,
  City,
  Season,
} from "@/models/enums";
import badmintonApi from "@/plugins/Api";
import { CreateLeagueCommand, UpdateLeagueCommand } from "@/api_client/models";

export default Vue.extend({
  data() {
    return {
      startMenu: false,
      endMenu: false,
      isCreating: false,
      isShowDialog: false,
      isEditing: false,
      isValid: false,
      isSaving: false,
      isDeleting: false,
      id: "",
      leagues: [],
      picture: "",
      imgFile: null,
      title: "",
      leagueType: "",
      leagueStatus: "",
      season: "",
      year: "",
      startDate: "",
      endDate: "",
      skillLevel: "",
      description: "",
      location: "",
      startDateRules: [(v: any) => !!v || "Start date is required"],
      endDateRules: [(v: any) => !!v || "End date is required"],
      LeagueStatuses: getEnumValues(LeagueStatus),
      LeagueTypes: getEnumValues(LeagueType),
      SkillLevels: getEnumValues(SkillLevel),
      Seasons:getEnumValues(Season),
      cities: getEnumValues(City),
      currentPageNumber: 1,
      countPerPage: 10,
      pageLength: 0,
    };
  },
  methods: {
    checkStartDate(startDate: any) {
      return (
        !this.endDate ||
        startDate < this.endDate ||
        "Start date much less than end date."
      );
    },
    checkEndDate(endDate: any) {
      return (
        !this.startDate ||
        endDate > this.startDate ||
        "End date must greater than start date."
      );
    },
    uploadImg() {
      var fileUpload = this.$refs.fileUpload as any;
      fileUpload.$el.firstElementChild.firstElementChild.firstElementChild.click();
    },
    showDeleteModal(league: any) {
      this.id = league.id;
      this.isShowDialog = true;
    },
    hideDeleteModal(league: any) {
      this.isShowDialog = false;
    },
    fileChanged() {
      badmintonApi.FileUploadApi?.apiFileUploadUploadImgPost(
        this.imgFile as any
      ).then(
        async (response) => {
          this.picture = response.data.value;
        },
        (error) => {}
      );
    },
    deleteLeague() {
      this.isDeleting = true;
      badmintonApi.LeaguesApi?.apiLeaguesIdDelete(this.id).then(
        (response) => {
          this.isDeleting = false;
          this.isShowDialog = false;
          this.loadLeagues();
        },
        (error) => {
          this.isDeleting = false;
        }
      );
    },
    save() {
      var form = this.$refs.form as any;
      if (form.validate()) {
        this.isSaving = true;
        var command = {
          title: this.title,
          type: this.leagueType,
          skillLevel: this.skillLevel,
          season: this.season,
          year: this.year,
          start: this.startDate,
          end: this.endDate,
          status: this.leagueStatus,
          description: this.description,
          location: this.location,
          picture: this.picture,
        } as CreateLeagueCommand;
        badmintonApi.LeaguesApi?.apiLeaguesPost(command).then(
          (response) => {
            this.isSaving = false;
            this.isCreating = false;
            this.loadLeagues();
          },
          (error) => {
            this.isSaving = false;
          }
        );
      }
    },
    cancelChange() {
      this.isEditing = false;
      this.isCreating = false;
      this.id = "";
      this.title = "";
      this.leagueType = "";
      this.skillLevel = "";
      this.season = "";
      this.year = "";
      this.startDate = "";
      this.endDate = "";
      this.leagueStatus = "";
      this.description = "";
      this.picture = "";
      this.location = "";
    },
    edit(league: any) {
      this.isEditing = true;
      this.id = league.id;
      this.title = league.title;
      this.leagueType = league.type;
      this.skillLevel = league.skillLevel;
      this.season = league.season;
      this.year = league.year;
      this.startDate = league.start?.substr(0, 10);
      this.endDate = league.end?.substr(0, 10);
      this.leagueStatus = league.status;
      this.description = league.description;
      this.picture = league.picture;
      this.location = league.location;
    },
    update() {
      var form = this.$refs.form as any;
      if (form.validate()) {
        this.isSaving = true;
        var command = {
          id: this.id,
          title: this.title,
          type: this.leagueType,
          skillLevel: this.skillLevel,
          season: this.season,
          year: this.year,
          status: this.leagueStatus,
          start: this.startDate,
          end: this.endDate,
          description: this.description,
          picture: this.picture,
          location: this.location,
        } as UpdateLeagueCommand;
        badmintonApi.LeaguesApi?.apiLeaguesPut(command).then(
          (response) => {
            this.isSaving = false;
            this.isEditing = false;
            this.loadLeagues();
          },
          (error) => {
            this.isSaving = false;
          }
        );
      }
    },
    loadLeagues() {
      badmintonApi.LeaguesApi?.apiLeaguesGet(
        this.currentPageNumber,
        this.countPerPage
      ).then((response) => {
        this.leagues = response.data.value.data;
        this.pageLength = response.data.value.totalPage;
      });
    },
  },
  watch: {
    currentPageNumber() {
      this.loadLeagues();
    },
  },
  mounted() {
    this.loadLeagues();
  },
  components: {
    Modal,
  },
});
