










































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      rows: [
        [
          "4 to 8 Players",
          "T-Shirt, $$",
          "$",
          "Free League"
        ],
        [
          "9 to 16 Players",
          "T-Shirt, Shop Item, $$$",
          "Shop Item, $$",
          "Shop Item, Free League"
        ],
        [
          "17-32 Players",
          "T-Shirt, Shop Item, $$$$",
          "Shop Item, $$$",
          "Shop Item, Free League, $"
        ]
      ]
    };
  },
});
