

















import badmintonApi from "@/plugins/Api";
import { SendTeamRequestCommand } from "@/api_client/models";
import Vue from "vue";
export default Vue.extend({
  props: {
    league: Object,
    player:Object,
    successCallback: Function,
  },
  data() {
    return {
      isShowDialog: false,
      isSendingTeamRequest: false,
    };
  },
  methods: {
    teamRequest(player: any) {
    
      this.isSendingTeamRequest = true;
      var command = {
        leagueId: this.league.id,
        from: this.$store.state.user.id,
        to: player.id,
      } as SendTeamRequestCommand;
      badmintonApi.MessagesApi?.apiMessagesTeamRequestPost(command).then(
        (response) => {
          this.isSendingTeamRequest = false;
          this.$props.successCallback();
        },
        (error) => {
          this.isSendingTeamRequest = false;
        }
      );
    },
  },
});
