






































































import router from "@/router";
import Modal from "../components/Modal.vue";
import Vue from "vue";
import badmintonApi from "@/plugins/Api";
import { JoinLeagueCommand } from "@/api_client/models";
export default Vue.extend({
  props: {
    league: Object,
    successCallback: Function,
  },
  data() {
    return {
      isShowJoinLeagueSuccessDialog: false,
      isShowDialog: false,
      isSendingJoinRequest: false,
    };
  },
  methods: {
    checkIsNeedShowConfirmModal() {
      if (this.$store.state.unauthorized) {
        router.push({ path: "/login" });
      } else {
        if (this.league.isDoubleLeague) {
          this.isShowDialog = true;
        } else {
          this.joinLeague();
        }
      }
    },
    hideDeleteModal() {
      this.isShowDialog = false;
    },
    closeJoinLeagueSuccessDialog() {
      this.isShowJoinLeagueSuccessDialog = false;
      this.$props.successCallback();
    },
    joinLeague() {
      this.isSendingJoinRequest = true;
      var command = {
        leagueId: this.$props.league.id,
      } as JoinLeagueCommand;
      badmintonApi.LeaguesApi?.apiLeaguesJoinPut(command).then(
        (response) => {
          this.isSendingJoinRequest = false;
          this.isShowDialog = false;
          this.isShowJoinLeagueSuccessDialog = true;
        },
        (error) => {
          this.isSendingJoinRequest = false;
        }
      );
    },
  },
  components: {
    Modal,
  },
});
