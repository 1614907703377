













































import badmintonApi from "@/plugins/Api";
import router from "@/router";
import { RegisterWithLoginCommand } from "@/api_client/models";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      valid: true,
      isRegistering: false,
      showErrorMessage: false,
      showSuccessMessage:false,
      errorMessage: "",
      email: "",
      emailRules: [
        (v: any) => !!v || "E-mail is required",
        (v: any) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      referralCodeRules: [
        (v: any) => !v || v.length == 6 || "Referral Code must be 6 characters",
      ],
      referralCode: "",
    };
  },
  methods: {
    register() {
      var form = this.$refs?.form as any;
      if (form.validate()) {
        this.isRegistering = true;
        var register = {
          dialog: false,
          email: this.email,
          referralCode: this.referralCode,
        } as RegisterWithLoginCommand;
        badmintonApi.UsersApi?.apiRegisterPost(register).then(
          (response) => {
            this.isRegistering = false;
            this.showSuccessMessage=true;
          },
          (error) => {
            this.isRegistering = false;
            this.showErrorMessage = true;
            this.errorMessage = error.response.data.Message;
          }
        );
      }
    },
  },
});
